<template>
  <div class="about">
    <nav-bar show-back="1">
      <div class="nav-title">Daftar pertanyaan</div>
    </nav-bar>
    <div class="about-info">
      <div class="about-item">
        <h3>1.我们的宗旨</h3>
        <!-- <p>
          夏目漱石三十岁的时候，曾经这样说：“人生二十而知有生的利益；二十五而知有明之处必有暗；至于三十的今日，更知明多之处暗亦多，欢浓之时愁亦重。”我现在对于这话也深抱同感；有时又觉得三十的特征不止这一端，其更特殊的是对于死的体感。
        </p>
        <p>
          There are moments in life when you miss someone so much that you just
          want to pick them from your dreams and hug them for real! Dream what
          you want to dream;go where you want to go;be what you want to
          be,because you have only one life and one chance to do all the things
          you want to do.
        </p>
        <p>1111111111111</p>
        <p>1111111111111</p>
      </div>
      <h2>我们的服务</h2>
      <p>1111111111111</p>
      <p>1111111111111</p>
      <p>1111111111111</p>
      <p>1111111111111</p> -->
        </div>
    </div>
  </div>
</template>
<script>
import NavBar from "../../components/navBar";
export default {
  components: { NavBar },
};
</script>
<style scoped lang="scss">
.about {
  padding-bottom: pxTo(48+20);

  @extend %page;
  background-color: $c1;
  .about-info {
    padding: pxTo(12);
    .about-item {
      padding: pxTo(8);
      width: 100%;
      height: pxTo(400);
      background-color: #f5f5f5;
      font-family: "Microsoft Yahei","SimHei", "SimSun", "Lucida Console", "Courier New",
        monospace;

      p {
        font-size: pxTo(14);
        font-family: "Microsoft Yahei","SimHei", "SimSun", "Lucida Console", "Courier New",
          monospace;
        color: goldenrod;
        overflow: hidden;
        word-break: break-word;
        text-align: justify;
      }
    }
  }
}
</style>